import axios, { AxiosError } from 'axios';
import { baseUrl } from '../constants/generic';

export const configureAxios = () => {
  axios.defaults.baseURL = baseUrl;
  axios.defaults.headers.common['Content-Type'] = 'application/json';
  axios.defaults.headers.common.Accept = 'application/json';

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error: AxiosError) => {
      if (error.response && error.response.status === 401) {
        window.location.href = '/login';
        localStorage.removeItem('user');
      }
    }
  );
};
