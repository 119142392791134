export const initLoader = () => {
  const loader = {
    loadingPage: {
      systemProducts: 'Hotels',
      systemProductsDetails: 'Loading',
      listOfGalleryId: [
        {
          code: '/s3/prod.beprotravel/135/BeTop/CMSGALLERY/loading/loader_c3a410fd.gif',
          alt: '',
        },
      ],
    },
  };
  sessionStorage.setItem('allLoadings', JSON.stringify({ loadings: [loader] }));
};
