import React, { Fragment } from 'react';
import { useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();

  return (
    <Fragment>
      {!location.pathname.includes('results') && (
        <div className="footer-wrapper">
          <div className="footer">
            <div className="col-md-3 col-6">
              <img src={require('../assets/img/logo.png')} alt="Logo" />
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default Footer;
