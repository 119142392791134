import React, { FC, useContext } from 'react';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useLayoutEffect } from 'react';
import { Page } from '../ts/interfaces/Generic';

import {
  BookHotelPage,
  ResultHotelPage,
  DetailsHotelPage,
  MainHotelPage,
  SuccessHotelPage,
  BookPayment,
} from '@bepro-travel/fe.hotel';
import { MainContext } from '../contexts/MainContext';
import LoginPage from '../pages/Login';
import { MainMOPage, MainPage, PreviewPage } from '@bepro-travel/fe.shared';
import NotFoundPage from '../pages/NotFoundPage';

const ResultHotel: FC = () => {
  const { proposals, setProposals } = useContext(MainContext);
  return <ResultHotelPage proposals={proposals} setProposals={setProposals} />;
};
const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useContext(MainContext);

  useLayoutEffect(() => {
    if (!user?.isAuth) {
      navigate('/login');
    }
    window.scrollTo(0, 0);
  }, [location.pathname]); // eslint-disable-line

  const pages: Page[] = [
    { path: '/', component: MainHotelPage },
    { path: '/hotels/results', component: ResultHotel },
    { path: '/hotel/details', component: DetailsHotelPage },
    { path: '/hotel/book', component: BookHotelPage },
    { path: '/hotel/payment', component: BookPayment },
    { path: '/hotel/success', component: SuccessHotelPage },
    { path: '/login', component: LoginPage },
    { path: '/my-orders', component: MainMOPage },
    { path: '/pages/*', component: MainPage },
    { path: '/preview', component: PreviewPage },
  ];

  return (
    <Routes>
      {pages.map(({ component: Component, path }) => {
        return <Route key={path} path={path} Component={Component} />;
      })}
      <Route element={<NotFoundPage />} path="*" />
    </Routes>
  );
};

export default Router;
