import { Validation } from '@bepro-travel/fe.utils';
import i18n from '../utils/i18n';

export const googleMapsKey = 'AIzaSyBxEwtMRXpcXdcMScNSUAF6G6sr9wwf3cA';
export const blobUrl = 'https://enigma-hotels.com/s3/prod.beprotravel/cdn/';
export const cdnUrl = 'https://enigma-hotels.com';

// export const reCaptchaKey = '6LcUjc8pAAAAAC_EN8Gqd9ZLK25XjF74c4EAAoNJ'; //Genesis
// export const baseUrl = 'https://www.genesis-club.co.il/'; //Genesis
export const baseUrl = 'https://enigma-hotels.com/';
export const reCaptchaKey = '6LfMDqwpAAAAAPQyZ1s7g7GJI4_j2rgyqIVcJKRV';

export const colorContrast = '#191e3b';
export const colorBorder = '#ddd';
export const colorDark = '#666';
export const colorStar = '#ffb700';
export const colorSuccess = '#4caf50';
export const colorDanger = '#f44336';
export const mapSupplier = 'google' as 'google' | 'osm';

export const getSortHotelOptions = () => [
  { value: 'recommended', label: i18n.t('sort.recommended') },
  { value: 'priceIncrease', label: i18n.t('sort.price_increase') },
  { value: 'priceDecrease', label: i18n.t('sort.price_decrease') },
  { value: 'starIncrease', label: i18n.t('sort.star_increase') },
  { value: 'starDecrease', label: i18n.t('sort.star_decrease') },
  { value: 'nameIncrease', label: i18n.t('sort.hotel_increase') },
  { value: 'nameDecrease', label: i18n.t('sort.hotel_decrease') },
  { value: 'distance', label: i18n.t('transfer.distance') },
];

export const sexOptions = [
  { value: 'Male', label: 'Male' },
  { value: 'Female', label: 'Female' },
];

export const getGender = () => {
  return [
    { value: 'Male', label: i18n.t('male') },
    { value: 'Female', label: i18n.t('female') },
  ];
};

export const credentialsValidation: Validation = {
  username: { required: true },
  password: { required: true },
  email: { required: true, email: true },
};

export const imgsCar = [
  require('../assets/img/vehicles/Private-Car-Regular.png'),
  require('../assets/img/vehicles/Private-Car-Luxury.png'),
  require('../assets/img/vehicles/Minivan-Shuttle.png'),
  require('../assets/img/vehicles/Minibus.png'),
  require('../assets/img/vehicles/Bus.png'),
  require('../assets/img/vehicles/Private-Car-Business.png'),
  require('../assets/img/vehicles/Minivan-Regular.png'),
  require('../assets/img/vehicles/Handicap.png'),
  require('../assets/img/vehicles/Private-Driver.png'),
  require('../assets/img/vehicles/Private-Car-Regular.png'),
  require('../assets/img/vehicles/Limousine.png'),
];

export const uiConfig = { colorBorder, colorContrast, colorStar };
export const hotelConfig = {
  googleMapsKey,
  mapSupplier,
  imgBgImg: require('../assets/img/newbg.jpg'),
  imgBluePoint: require('../assets/img/blue.png'),
  imgRedPoint: require('../assets/img/red.png'),
  imgWaypointA: require('../assets/img/waypoint-a.png'),
  imgWaypointB: require('../assets/img/waypoint-b.png'),
  imgCenterPoint: require('../assets/img/center.png'),
  imgLoaderMain: require('../assets/img/loader.gif'),
  imgPaymentLoader: require('../assets/img/loader.gif'),
  cdnUrl,
  getPage: () => Promise.resolve({}),
  setFooter: () => {},
  setHeader: () => {},
  setSiteConfig: () => {},
  b2b: true,
  needToScreenshot: true,
  showOrderCompanyId: true,
  showOrderTripId: true,
};
