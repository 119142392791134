import React, { FC, Fragment, useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ProposalModal } from '@bepro-travel/fe.hotel';
import { MainContext } from '../contexts/MainContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faSearch, faUser } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { dateFormat } from '../utils/i18n';
import {
  Accessibility,
  ConfigModal,
  ScreenshotSupport,
  SearchHistory,
  auth,
} from '@bepro-travel/fe.shared';

const Header: FC = () => {
  const [t] = useTranslation();
  const [urlParams] = useSearchParams();
  const [isOpenProposal, setIsOpenProposal] = useState(false);
  const { proposals, setUser, user, setProposals } = useContext(MainContext);
  const [hamburgerState, setHamburgerState] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [isHoverElement, setIsHoverElement] = useState(-2);

  useEffect(() => {
    const lang = localStorage.getItem('language');

    if (lang === 'he') {
      document.body.classList.add('rtl');
    }
  }, []);

  const onPressHanburger = () => {
    document.querySelector('.header-fields')!.classList.toggle('open');
    setHamburgerState(!hamburgerState);
  };

  const onClickProposal = () => {
    setIsOpenProposal(!isOpenProposal);
    onClickHeaderCell();
  };

  const onClickLogoff = async () => {
    await auth.logout();
    setUser(undefined);
    navigate('/login');
    onClickHeaderCell();
  };

  const onClickHeaderCell = () => {
    document.querySelector('.header-fields')!.classList.remove('open');
    setHamburgerState(false);
  };

  return (
    <Fragment>
      <div className="header">
        <Link className="header-logo-wr" to="/" onClick={onClickHeaderCell}>
          <img className="header-logo" src={require('../assets/img/logo.png')} alt="Logo" />
        </Link>
        {!!urlParams.get('checkIn') && (
          <div
            className="header-search"
            onClick={() => {
              const element = document.querySelector('.search-wrapper') as HTMLElement;
              element?.classList.toggle('visible-responsive');
            }}
          >
            <FontAwesomeIcon icon={faSearch} className="search-img" />
            <div className="header-search-text">
              <div className="header-search-city">{urlParams.get('city')}</div>
              <div className="header-search-date">
                <div>{dateFormat(urlParams.get('checkIn')!, 'dd MMM')}</div>
                {!!urlParams.get('checkOut') && (
                  <Fragment>
                    <FontAwesomeIcon icon={faCircle} />
                    <div>{dateFormat(urlParams.get('checkOut')!, 'dd MMM')}</div>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        )}

        <span className="hamburger">
          <input
            id="hamburger__toggle"
            type="checkbox"
            onChange={onPressHanburger}
            checked={hamburgerState}
          />
          <label className="hamburger__btn" htmlFor="hamburger__toggle">
            <span></span>
          </label>
        </span>
        <div className="header-fields">
          <div className="header-oposite"></div>
          {user?.isAuth && (
            <Fragment>
              {!!user?.isAuth && ['/', '/hotels/results'].includes(pathname) && <SearchHistory />}
              <div className="header-cell" onClick={onClickHeaderCell}>
                <Link to={''} className="header-cell-text">
                  {t('hotel.s')}
                </Link>
              </div>
              <div className="header-cell" onClick={onClickProposal}>
                <span className="header-cell-text">
                  {t('proposal.proposal')}
                  {!!proposals.length && (
                    <span className="proposal-header">{proposals.length}</span>
                  )}
                </span>
              </div>
              <div className="header-cell" onClick={onClickHeaderCell}>
                <Link to={'my-orders'} className="header-cell-text">
                  {t('mo.mo')}
                </Link>
              </div>
              <div className="header-cell" onClick={onClickLogoff}>
                <span className="header-cell-text">{t('login.off')}</span>
              </div>

              <div className="header-cell mr-5">
                <ConfigModal />
              </div>

              <div className="header-cell mr-5">
                <ScreenshotSupport />
              </div>

              <div
                className="header-cell header-cell-last"
                onMouseLeave={() => setIsHoverElement(-2)}
                onMouseEnter={() => setIsHoverElement(-1)}
              >
                <FontAwesomeIcon icon={faUser} />

                <div className={'header-dropdown' + (isHoverElement === -1 ? ' open' : '')}>
                  <div className="header-dropdown-row ph-10">
                    <div className="ph-10 mt-10 mb-15 pb-5 bb-1">
                      <span className="text-bold">{t('mo.hello')}, </span>
                      <span className=" text-lg text-contrast">{user?.username}</span>
                    </div>
                    <div className="ph-10 mt-10 mb-15 text-sm">
                      <span className="text-bold">{t('mo.user_code')}</span>: {user?.userCode} -{' '}
                      {user?.username}
                    </div>
                    <div className="ph-10 mt-10 mb-15 text-sm">
                      <span className="text-bold">{t('mo.branch')}</span>: {user?.branchId} -{' '}
                      {user?.branch?.branchTitle}
                    </div>
                    <div className="ph-10 mt-10 mb-15 text-sm">
                      <span className="text-bold">{t('mo.department')}</span>: {user?.departmentId}{' '}
                      - {user?.department?.departmentTitle}
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          )}

          {!user?.isAuth && (
            <div className="header-cell" onClick={onClickHeaderCell}>
              <Link to={'login'} className="header-cell-text">
                {t('login.login')}
              </Link>
            </div>
          )}
        </div>
      </div>
      {isOpenProposal && (
        <ProposalModal
          onClose={() => setIsOpenProposal(false)}
          proposals={proposals}
          setProposals={setProposals}
        />
      )}
      <Accessibility />
    </Fragment>
  );
};

export default Header;
